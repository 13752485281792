import config from 'config'
import createClient from '@auth0/auth0-spa-js'

export type LoginOptions = {
  ui_locales: 'de'
  redirect_uri: string
  return_to?: string
  appState?: {
    targetUrl: string
  }
  screen_hint?: 'signup'
  prompt?: 'login'
}

export type Auth0User = {
  'https://relaxcommerce.com/wishlistId': string
  'https://relaxcommerce.com/firstname': string
  'https://relaxcommerce.com/lastname': string
  'https://relaxcommerce.com/hasNewsletterRequested': boolean
  'https://relaxcommerce.com/isSocialSignin': boolean
  'https://relaxcommerce.com/signedinWith': string
  email: string
}

type Client = {
  loginWithRedirect: (options: LoginOptions) => void
  getUser: () => null | Auth0User
  logout: () => void
  handleRedirectCallback: () => Promise<{
    appState?: {
      targetUrl?: string
    }
  }>
}

let client: Client | null = null

export const fetchClient = async (): Promise<Client> => {
  if (!client) {
    // @ts-ignore
    client = await createClient({
      domain: config.modules.auth0.domain,
      client_id: config.modules.auth0.clientId,
      scope: 'openid profile email',
      cacheLocation: 'localstorage',
    })
    // @ts-ignore
    window.auth0 = client
  }
  return client as Client
}

export const createLoginOptions = (redirectUrl: string, signup: boolean): LoginOptions => {
  let options: LoginOptions = {
    ui_locales: 'de',
    redirect_uri: `${window.top.location.origin}/callback-auth`,
  }
  if (redirectUrl) {
    options.return_to = redirectUrl
    options.appState = { targetUrl: redirectUrl }
  }
  if (signup) {
    // https://auth0.com/docs/universal-login/new
    options.screen_hint = 'signup'
    options.prompt = 'login'
  }

  return options
}
